import React from "react"

import HeaderServices from "../../components/header_services"

export default function ClientEngagement() {
  const heroTitle = "Client Engagement"
  return (
    <HeaderServices heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <p>In all our engagements with our clients, we ensure that:</p>

      <ul class="list-icon list-icon-check list-icon-colored mt-3">
        <li>we are free of any conflicts</li>
        <li>we are honest and transparent in the advice we offer and the way we deal with you</li>
        <li>
          we seek your periodic feedback and your perception about dealings with us to ensure it
          meets your expectations or to identify any improvements required
        </li>
        <li>we do not place our interests or that of any third party above yours</li>
        <li>we are clear and concise in our communication</li>
        <li>we abide by the code of ethics and standards of the CFA Institute (US) and SEBI</li>
        <li>we do not sell any cookie cutter financial plans or portfolios</li>
        <li>we act as a financial guardian</li>
      </ul>
    </HeaderServices>
  )
}
